import { lazy, Suspense } from 'react';
import { Outlet } from 'react-router-dom';
import { AuthGuard } from 'src/auth/guard';
import { LoadingScreen } from 'src/components/loading-screen';
import DefaultLayout from 'src/layouts/default';
import { paths } from '../paths';

const TemplatesPage = lazy(() => import('src/pages/templates/index'));
const TemplatesRegisterPage = lazy(() => import('src/pages/templates/register'));
const TemplatesEditPage = lazy(() => import('src/pages/templates/edit'));

export const templatesRoutes = [
  {
    element: (
      <AuthGuard>
        <DefaultLayout>
          <Suspense fallback={<LoadingScreen />}>
            <Outlet />
          </Suspense>
        </DefaultLayout>
      </AuthGuard>
    ),
    children: [
      {
        path: paths.templates.root,
        element: <TemplatesPage />,
      },
      {
        path: paths.templates.register,
        element: <TemplatesRegisterPage />,
      },
      {
        path: paths.templates.detail.edit,
        element: <TemplatesEditPage />,
      },
    ],
  },
];
