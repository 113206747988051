import { TablePaginationData } from 'src/components/table/types';
import { CurrentRoleName, UserTableFilter } from 'src/types/user';

const ADMIN_DOCTOR_ROLE: CurrentRoleName = 'admin-doctor';
const ADMIN_STAFF_ROLE: CurrentRoleName = 'admin-staff';
export const USER_ROLE_DOCTOR: CurrentRoleName = 'doctor';
export const USER_ROLE_STAFF: CurrentRoleName = 'staff';

export const USER_ROLE_ADMIN_ONLY = [ADMIN_DOCTOR_ROLE, ADMIN_STAFF_ROLE];
export const USER_ROLE_ALL = [
  ADMIN_DOCTOR_ROLE,
  ADMIN_STAFF_ROLE,
  USER_ROLE_DOCTOR,
  USER_ROLE_STAFF,
];

export const USER_ROLE_NAME: Record<CurrentRoleName, string> = {
  [ADMIN_DOCTOR_ROLE]: '医師（管理者）',
  [ADMIN_STAFF_ROLE]: 'スタッフ(管理者)',
  [USER_ROLE_DOCTOR]: '医師',
  [USER_ROLE_STAFF]: 'スタッフ',
};

export const USER_ROLE_TYPE = {
  doctor: 1,
  staff: 2,
} as const;

export const USER_ROLE_OPTIONS = [
  { value: USER_ROLE_TYPE.doctor, label: '医師' },
  { value: USER_ROLE_TYPE.staff, label: 'スタッフ' },
];

export const USER_TABLE_HEAD = [
  { id: 'id', label: 'ID', width: 80 },
  { id: 'fullName', label: 'アカウント名', width: 120 },
  { id: 'fullNameKana', label: 'アカウント名カナ', width: 120 },
  { id: 'isAdmin', label: '権限', width: 80 },
  { id: 'role', label: '種別', width: 80 },
];

export const USER_TABLE_DEFAULT_FILTER: UserTableFilter = {
  fullName: '',
  fullNameKana: '',
  role: '',
  isAdmin: 'all',
};

export const USER_TABLE_DEFAULT_PAGINATION: TablePaginationData = {
  page: '',
  perPage: '',
};
