import { Navigate, useRoutes } from 'react-router-dom';
// config
import { PATH_AFTER_LOGIN } from 'src/config-global';
//
import { errorRoutes } from './error';

import { paths } from '../paths';
import { authRoutes } from './auth';
import { consentFormsRoutes } from './consentForms';
import { contactRoutes } from './contact';
import { defaultDoctorRoutes } from './defaultDoctor';
import { myAccountRoutes } from './myAccount';
import { organizationRoutes } from './organization';
import { patientsRoutes } from './patients';
import { templatesRoutes } from './templates';
import { usersRoutes } from './users';

// ----------------------------------------------------------------------

export default function Router() {
  return useRoutes([
    {
      path: '/',
      element: <Navigate to={PATH_AFTER_LOGIN} replace />,
    },

    ...authRoutes,

    ...consentFormsRoutes,

    ...patientsRoutes,

    ...templatesRoutes,

    ...defaultDoctorRoutes,

    ...myAccountRoutes,

    ...errorRoutes,

    ...usersRoutes,

    ...contactRoutes,

    ...organizationRoutes,

    // No match 404
    { path: '*', element: <Navigate to={paths.error['404']} replace /> },
  ]);
}
