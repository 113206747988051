// routes
import { paths } from 'src/routes/paths';
// components
import { EXTERNAL_LINK_URL } from 'src/commons/const/externalLinkUrl';
import { MENU_TITLES, PAGE_TITLES } from 'src/commons/const/titles';
import { USER_ROLE_ADMIN_ONLY, USER_ROLE_ALL } from 'src/commons/const/user';
import Iconify from 'src/components/iconify/iconify';

// ----------------------------------------------------------------------

export function useNavData() {
  const data = [
    {
      items: [
        {
          title: MENU_TITLES.consentForms,
          path: paths.consentForms.root,
          roles: USER_ROLE_ALL,
          icon: <Iconify width={32} icon="basil:document-outline" />,
        },
      ],
    },
    {
      items: [
        {
          title: MENU_TITLES.patients,
          path: paths.patients.root,
          roles: USER_ROLE_ALL,
          icon: <Iconify width={32} icon="fluent:contact-card-20-regular" />,
          children: [
            { title: PAGE_TITLES.patients.root, path: paths.patients.root },
            { title: PAGE_TITLES.patients.register, path: paths.patients.register },
          ],
        },
      ],
    },
    {
      items: [
        {
          title: MENU_TITLES.templates,
          path: paths.templates.root,
          roles: USER_ROLE_ALL,
          icon: <Iconify width={32} icon="icomoon-free:insert-template" />,
          children: [
            { title: PAGE_TITLES.templates.root, path: paths.templates.root },
            { title: PAGE_TITLES.templates.register, path: paths.templates.register },
          ],
        },
      ],
    },
    {
      items: [
        {
          title: MENU_TITLES.defaultDoctor,
          path: paths.defaultDoctor.root,
          roles: USER_ROLE_ALL,
          icon: <Iconify width={24} icon="fa6-solid:user-doctor" />,
        },
      ],
    },
    {
      items: [
        {
          title: MENU_TITLES.users,
          path: paths.users.root,
          roles: USER_ROLE_ADMIN_ONLY,
          icon: <Iconify width={32} icon="majesticons:user-box-line" />,
          children: [
            { title: PAGE_TITLES.users.root, path: paths.users.root },
            { title: PAGE_TITLES.users.register, path: paths.users.register },
          ],
        },
      ],
    },
    {
      items: [
        {
          title: MENU_TITLES.organization,
          path: paths.organization.root,
          roles: USER_ROLE_ADMIN_ONLY,
          icon: <Iconify width={32} icon="icons8:organization" />,
        },
      ],
    },
    {
      items: [
        {
          title: MENU_TITLES.contact,
          path: paths.contact.root,
          roles: USER_ROLE_ADMIN_ONLY,
          icon: <Iconify width={32} icon="mdi:contact-mail" />,
        },
      ],
    },
    {
      items: [
        {
          title: MENU_TITLES.terms,
          path: EXTERNAL_LINK_URL.terms,
          roles: USER_ROLE_ALL,
          icon: <Iconify width={32} icon="carbon:rule" />,
        },
      ],
    },
    {
      items: [
        {
          title: MENU_TITLES.privacyPolicy,
          path: EXTERNAL_LINK_URL.privacyPolicy,
          roles: USER_ROLE_ALL,
          icon: <Iconify width={32} icon="iconoir:privacy-policy" />,
        },
      ],
    },
  ];

  return data;
}
