import { m } from 'framer-motion';
// @mui
import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';
import MenuItem from '@mui/material/MenuItem';
// routes
import { useRouter } from 'src/routes/hooks';
// hooks
import { useAuthContext } from 'src/auth/hooks';
// components
import { varHover } from 'src/components/animate';
import CustomPopover, { usePopover } from 'src/components/custom-popover';
import { PAGE_TITLES } from 'src/commons/const/titles';
import { paths } from 'src/routes/paths';
import { Button, IconButton } from '@mui/material';
import Iconify from 'src/components/iconify';
import { ConfirmDialog } from 'src/components/custom-dialog';
import { useBoolean } from 'src/hooks/use-boolean';

// ----------------------------------------------------------------------

export default function AccountPopover() {
  const router = useRouter();

  const { logout } = useAuthContext();

  const popover = usePopover();

  const confirmLogout = useBoolean();

  const handleLogout = async () => {
    try {
      await logout();
      popover.onClose();
      router.replace('/');
    } catch (error) {
      console.error(error);
    }
  };

  const handleClickItem = (path: string) => {
    popover.onClose();
    router.push(path);
  };

  return (
    <>
      <IconButton
        component={m.button}
        whileTap="tap"
        whileHover="hover"
        variants={varHover(1.05)}
        onClick={popover.onOpen}
      >
        <Iconify width={24} icon="solar:settings-bold" />
      </IconButton>

      <CustomPopover open={popover.open} onClose={popover.onClose} sx={{ width: 200, p: 0 }}>
        <Divider sx={{ borderStyle: 'dashed' }} />

        <Stack sx={{ p: 1 }}>
          <MenuItem
            key={PAGE_TITLES.myAccount.root}
            onClick={() => handleClickItem(paths.myAccount.root)}
          >
            {PAGE_TITLES.myAccount.root}
          </MenuItem>
        </Stack>

        <Divider sx={{ borderStyle: 'dashed' }} />

        <MenuItem
          onClick={confirmLogout.onTrue}
          sx={{ m: 1, fontWeight: 'fontWeightBold', color: 'error.main' }}
        >
          ログアウト
        </MenuItem>
      </CustomPopover>
      <ConfirmDialog
        open={confirmLogout.value}
        onClose={confirmLogout.onFalse}
        title="ログアウト"
        content="本当にログアウトしてもよろしいですか？"
        action={
          <Button variant="contained" color="error" onClick={handleLogout}>
            ログアウト
          </Button>
        }
      />
    </>
  );
}
