import { Outlet, RouteObject } from 'react-router';
import { AuthGuard, AdminGuard } from 'src/auth/guard';
import DefaultLayout from 'src/layouts/default/layout';
import { Suspense, lazy } from 'react';
import { LoadingScreen } from 'src/components/loading-screen';
import { paths } from '../paths';

const ContactPage = lazy(() => import('src/pages/contact'));

export const contactRoutes: RouteObject[] = [
  {
    element: (
      <AuthGuard>
        <AdminGuard>
          <DefaultLayout>
            <Suspense fallback={<LoadingScreen />}>
              <Outlet />
            </Suspense>
          </DefaultLayout>
        </AdminGuard>
      </AuthGuard>
    ),
    children: [
      {
        path: paths.contact.root,
        element: <ContactPage />,
      },
    ],
  },
];
