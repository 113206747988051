import { lazy, Suspense } from 'react';
import { Outlet } from 'react-router-dom';
import { AuthGuard } from 'src/auth/guard';
import { LoadingScreen } from 'src/components/loading-screen';
import DefaultLayout from 'src/layouts/default';
import CompactLayout from 'src/layouts/compact';
import { paths } from '../paths';

const MyAccountPage = lazy(() => import('src/pages/myAccount'));
const ChangePasswordPage = lazy(() => import('src/pages/myAccount/changePassword'));
const ChangePasswordCompletePage = lazy(() => import('src/pages/myAccount/completeChangePassword'));

// ----------------------------------------------------------------------

export const myAccountRoutes = [
  {
    element: (
      <AuthGuard>
        <DefaultLayout>
          <Suspense fallback={<LoadingScreen />}>
            <Outlet />
          </Suspense>
        </DefaultLayout>
      </AuthGuard>
    ),
    children: [
      { path: paths.myAccount.root, element: <MyAccountPage /> },
      { path: paths.myAccount.changePassword.root, element: <ChangePasswordPage /> },
    ],
  },
  {
    element: (
      <CompactLayout>
        <Outlet />
      </CompactLayout>
    ),
    children: [
      { path: paths.myAccount.changePassword.complete, element: <ChangePasswordCompletePage /> },
    ],
  },
];
