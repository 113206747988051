import { USER_ROLE_DOCTOR, USER_ROLE_NAME, USER_ROLE_STAFF } from 'src/commons/const/user';
import { CurrentRoleName } from 'src/types/user';
import { getFullName } from 'src/utils/user';
import { useAuthContext } from './use-auth-context';

export const useUser = () => {
  const authContext = useAuthContext();

  const { user } = authContext;

  const getCurrentRole = (
    isAdmin: boolean,
    isDoctor: boolean,
    isStaff: boolean
  ): CurrentRoleName => {
    const roles = [];

    if (isAdmin) {
      roles.push('admin');
    }

    if (isDoctor) {
      roles.push('doctor');
    }

    if (isStaff) {
      roles.push('staff');
    }

    return roles.join('-') as CurrentRoleName;
  };

  const id = user?.id || '';
  const isAdmin = !!user?.isAdmin;
  const isDoctor = user?.role.name === USER_ROLE_DOCTOR;
  const isStaff = user?.role.name === USER_ROLE_STAFF;
  const fullName = user ? getFullName(user) : '';
  const organizationId = String(user?.organization.id || '');
  const organizationName = user?.organization.name || '';
  const currentRole: CurrentRoleName = getCurrentRole(isAdmin, isDoctor, isStaff);
  const roleName = USER_ROLE_NAME[currentRole];
  const defaultDoctor = user?.defaultDoctor;

  return {
    id,
    isAdmin,
    isDoctor,
    isStaff,
    fullName,
    organizationId,
    organizationName,
    currentRole,
    roleName,
    defaultDoctor,
  };
};
