import { useMemo } from 'react';
import { SetURLSearchParams, useSearchParams as _useSearchParams } from 'react-router-dom';

// ----------------------------------------------------------------------

export function useSearchParams(): [URLSearchParams, SetURLSearchParams] {
  const [searchParams, setSearchParams] = _useSearchParams();

  return useMemo(() => [searchParams, setSearchParams], [searchParams, setSearchParams]);
}
