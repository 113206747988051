import axios, { AxiosRequestConfig } from 'axios';
import { HOST_API } from 'src/config-global';
import { paths } from 'src/routes/paths';

const axiosInstance = axios.create({ baseURL: `${HOST_API}/api/v1` });

export const STORAGE_KEY = 'accessToken';
export const REFRESH_KEY = 'refreshToken';

axiosInstance.interceptors.response.use(
  (res) => res,
  (error) => {
    if (
      error.response &&
      error.response.status === 403 &&
      error.response.data.error === 'トークンの期限切れです。'
    ) {
      setSession(null);
      window.location.href = paths.auth.login;
    }
    const errorStatus = error.response?.status || '';
    return Promise.reject(
      error.response?.data?.errors?.message ||
        `通信エラーが発生しました。エラーコード${errorStatus}`
    );
  }
);

export default axiosInstance;

export const setSession = (accessToken: string | null) => {
  if (accessToken) {
    sessionStorage.setItem(STORAGE_KEY, accessToken);
    axiosInstance.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
  } else {
    sessionStorage.removeItem(STORAGE_KEY);

    delete axiosInstance.defaults.headers.common.Authorization;
  }
};

export const fetcher = async (args: string | [string, AxiosRequestConfig]) => {
  const [url, config] = Array.isArray(args) ? args : [args];
  const res = await axiosInstance.get(url, { ...config });
  return res.data;
};

export const endpoints = {
  auth: {
    me: `/auth/user/me`,
    login: `/auth/user/login`,
    refresh: `/auth/user/refresh`,
    register: `/auth/register`,
  },
  organizations: {
    detail: `/organizations/{organizationId}`,
  },
  users: {
    root: `/organizations/{organizationId}/users`,
    pass: `/organizations/{organizationId}/users/pass`,
    email: `/organizations/{organizationId}/users/email`,
    default: `/organizations/{organizationId}/users/default`,
    cancel: `/organizations/{organizationId}/users/cancel`,
    detail: {
      root: `/organizations/{organizationId}/users/{userId}`,
      passReset: `/organizations/{organizationId}/users/{userId}/pass-reset`,
    },
  },
  patients: {
    root: `/organizations/{organizationId}/patients`,
    detail: {
      root: `/organizations/{organizationId}/patients/{patientId}`,
      url: `/organizations/{organizationId}/patients/{patientId}/url`,
    },
  },
  consentForms: {
    root: `/organizations/{organizationId}/consent-forms`,
    detail: `/organizations/{organizationId}/consent-forms/{consentFormId}`,
  },
  doctors: {
    root: `/organizations/{organizationId}/doctors`,
  },
  patientConsents: {
    root: `/organizations/{organizationId}/patients/{patientId}/patient-consents`,
    signature: `/organizations/{organizationId}/patients/{patientId}/patient-consents/signature`,
    detail: {
      root: `/organizations/{organizationId}/patients/{patientId}/patient-consents/{patientConsentId}`,
      status: `/organizations/{organizationId}/patients/{patientId}/patient-consents/{patientConsentId}/status`,
      signature: `/organizations/{organizationId}/patients/{patientId}/patient-consents/{patientConsentId}/signature`,
      download: `/organizations/{organizationId}/patients/{patientId}/patient-consents/{patientConsentId}/download`,
      reissue: `/organizations/{organizationId}/patients/{patientId}/patient-consents/{patientConsentId}/reissue`,
    },
  },
};
