import { lazy, Suspense } from 'react';
import { Outlet, RouteObject } from 'react-router';
import { AuthGuard } from 'src/auth/guard';
import { LoadingScreen } from 'src/components/loading-screen';
import DefaultLayout from 'src/layouts/default/layout';
import { paths } from '../paths';

const PatientsSelectPage = lazy(() => import('src/pages/consentForms/'));
const PatientConsentsPage = lazy(() => import('src/pages/consentForms/detail/patientConsents'));
const TemplateSelectPage = lazy(() => import('src/pages/consentForms/detail/templates'));
const ConsentFormsRegisterPage = lazy(() => import('src/pages/consentForms/detail/register'));
const DisplayMethodPage = lazy(() => import('src/pages/consentForms/detail/displayMethod'));
const ConsentFormsSignaturePage = lazy(() => import('src/pages/consentForms/detail/signature'));

export const consentFormsRoutes: RouteObject[] = [
  {
    element: (
      <AuthGuard>
        <DefaultLayout>
          <Suspense fallback={<LoadingScreen />}>
            <Outlet />
          </Suspense>
        </DefaultLayout>
      </AuthGuard>
    ),
    children: [
      {
        path: paths.consentForms.root,
        element: <PatientsSelectPage />,
      },
      {
        path: paths.consentForms.detail.templates,
        element: <TemplateSelectPage />,
      },
      {
        path: paths.consentForms.detail.patientConsents,
        element: <PatientConsentsPage />,
      },
      {
        path: paths.consentForms.detail.register,
        element: <ConsentFormsRegisterPage />,
      },
      {
        path: paths.consentForms.detail.displayMethod,
        element: <DisplayMethodPage />,
      },
      {
        path: paths.consentForms.detail.signature.root,
        element: <ConsentFormsSignaturePage />,
      },
    ],
  },
];
