// routes
import { User } from 'src/types/user';
// utils
import axios, { endpoints, setSession } from 'src/utils/axios';

// ----------------------------------------------------------------------

export const jwtLogin = async (
  id: string,
  password: string
): Promise<{
  accessToken: string;
  user: User;
}> => {
  if (!id || !password) {
    throw new Error('ID and password are required');
  }

  const data = {
    id,
    password,
  };

  const response = await axios.post<{
    token: string;
    refreshToken: string;
    user: User;
  }>(endpoints.auth.login, data);

  const { token, user } = response.data;

  setSession(token);

  return {
    accessToken: token,
    user,
  };
};
