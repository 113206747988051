import { lazy, Suspense } from 'react';
import { Outlet, RouteObject } from 'react-router';
import { AuthGuard } from 'src/auth/guard';
import { LoadingScreen } from 'src/components/loading-screen';
import DefaultLayout from 'src/layouts/default/layout';
import { paths } from '../paths';

const DefaultDoctorPage = lazy(() => import('src/pages/defaultDoctor'));

export const defaultDoctorRoutes: RouteObject[] = [
  {
    element: (
      <AuthGuard>
        <DefaultLayout>
          <Suspense fallback={<LoadingScreen />}>
            <Outlet />
          </Suspense>
        </DefaultLayout>
      </AuthGuard>
    ),
    children: [
      {
        path: paths.defaultDoctor.root,
        element: <DefaultDoctorPage />,
      },
    ],
  },
];
