import { Patient } from 'src/types/patient';
import { PatientInfo } from 'src/types/consentForms';
import { CommonUser, User } from 'src/types/user';

export const getFullName = (user?: CommonUser | Patient | PatientInfo) =>
  `${user?.lastName ? user.lastName.trim() : ''} ${user?.firstName ? user.firstName.trim() : ''}`;

export const getFullNameKana = (user?: User | Patient | PatientInfo) =>
  `${user?.lastNameKana ? user.lastNameKana.trim() : ''} ${
    user?.firstNameKana ? user.firstNameKana.trim() : ''
  }`;
