import { Suspense } from 'react';
import * as Sentry from '@sentry/react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
//
import App from './App';

// ----------------------------------------------------------------------

if (import.meta.env.VITE_SENTRY_CLIENT_DSN) {
  Sentry.init({
    dsn: import.meta.env.VITE_SENTRY_CLIENT_DSN,
    environment: import.meta.env.MODE,
    ignoreErrors: ['ChunkLoadError'],
  });
}

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
  <HelmetProvider>
    <BrowserRouter>
      <Suspense>
        <App />
      </Suspense>
    </BrowserRouter>
  </HelmetProvider>
);
