import { Box, BoxProps } from '@mui/material';
import { styled } from '@mui/material/styles';

const Svg = styled('svg')(() => ({
  '.cls-1': {
    fill: '#2797ba',
  },
  '.cls-1, .cls-2 ,.cls-3': {
    strokeWidth: 0,
  },
  '.cls-2': {
    fill: '#9fa0a0',
  },
  '.cls-3': {
    fill: '#d5311d',
  },
}));

const Logo = (sx: BoxProps['sx']) => (
  <Box sx={{ width: 120, ...sx }}>
    <Svg
      id="_レイヤー_1"
      data-name="レイヤー 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 283.46 104.8"
    >
      <g>
        <path
          className="cls-2"
          d="m81.29,15.74c-2.04,4.34-5.99,6.74-10.47,7.66v-4.16c4.73-1.66,7.42-4.89,7.42-11.03v-1.24h4.51v1.5c0,.97-.06,1.9-.17,2.76,3.56-1,5.43-3.37,6.08-6.45h-18.67V.68h23.96v.87c-.17,9.32-6.49,13.19-12.65,14.19Z"
        />
        <path
          className="cls-2"
          d="m124.91,9.29v14.53h-4.93v-12.11c-3.83,1.47-8.01,2.34-11.65,2.84v-4.18c11.84-1.95,16.91-5.98,18.95-10.37h5.15c-1.26,4.13-4.03,7.11-7.53,9.29Z"
        />
        <path className="cls-2" d="m148.94,22.32v-4.13h17.19V5.5h-17.16V1.4h22.14v20.92h-22.17Z" />
        <path
          className="cls-2"
          d="m190.27,5.16V.84c3.81.21,8.57.76,12.37,1.42v4.45c-3.58-.68-8.73-1.34-12.37-1.55Zm.39,17.87v-4.34c10.33-.68,16.8-3.45,17.83-14.08h4.98c-1.01,13-9.04,18.05-22.82,18.42Z"
        />
      </g>
      <rect className="cls-1" x="84.89" y="93.82" width="31.49" height="10.8" />
      <rect className="cls-1" y="59.45" width="11.65" height="44.81" />
      <rect className="cls-3" x="0" y="40.4" width="11.65" height="11.65" rx="5.83" ry="5.83" />
      <path
        className="cls-1"
        d="m53.34,104.8c-8.77,0-16.36-3.15-22.57-9.36-6.21-6.21-9.36-13.8-9.36-22.57s3.15-16.36,9.36-22.57c6.21-6.21,13.8-9.36,22.57-9.36,7.01,0,13.36,2.06,18.89,6.12,5.51,4.05,9.38,9.36,11.5,15.79l1.17,3.55h-12.16l-.71-1.62c-1.62-3.71-4.07-6.63-7.49-8.91-3.41-2.27-7.07-3.38-11.2-3.38-5.62,0-10.31,1.95-14.32,5.96-4.01,4.01-5.96,8.73-5.96,14.42s1.95,10.31,5.96,14.32c4.01,4.01,8.69,5.96,14.32,5.96,4.13,0,7.79-1.1,11.2-3.38,3.42-2.28,5.87-5.19,7.49-8.91l.71-1.62h12.16l-1.17,3.55c-2.12,6.43-5.99,11.76-11.49,15.84-5.53,4.1-11.88,6.18-18.9,6.18Z"
      />
      <path
        className="cls-3"
        d="m148.32,104.8c-8.77,0-16.37-3.15-22.57-9.36-6.21-6.21-9.36-13.8-9.36-22.57s3.15-16.36,9.36-22.57c6.21-6.21,13.81-9.36,22.57-9.36s16.36,3.15,22.57,9.36c6.21,6.21,9.36,13.81,9.36,22.57s-3.15,16.36-9.36,22.57c-6.21,6.21-13.8,9.36-22.57,9.36Zm0-52.31c-5.63,0-10.33,1.95-14.38,5.97-4.04,4.01-6,8.72-6,14.41s1.95,10.31,5.96,14.32c4.01,4.01,8.73,5.96,14.42,5.96s10.4-1.95,14.42-5.96c4.01-4.01,5.96-8.69,5.96-14.32s-1.95-10.41-5.96-14.42c-4.01-4.01-8.73-5.96-14.42-5.96Z"
      />
      <path
        className="cls-1"
        d="m215.2,104.42v-29.56c0-2.39-.79-4.3-2.49-6-1.7-1.7-3.61-2.49-6-2.49s-4.32.8-6.05,2.5c-1.72,1.7-2.52,3.6-2.52,5.99v29.56h-11.68v-29.56c0-5.53,1.99-10.32,5.92-14.25,3.93-3.93,8.75-5.92,14.33-5.92s10.32,1.99,14.25,5.92c3.93,3.93,5.92,8.73,5.92,14.25v29.56h-11.68Z"
      />
      <path
        className="cls-1"
        d="m269.63,86.53c-.51.76-1.09,1.48-1.78,2.17-2.66,2.66-5.78,3.95-9.55,3.95s-6.9-1.29-9.55-3.95c-1.03-1.03-1.84-2.12-2.47-3.29l25.41-4.48h0s11.78-2.08,11.78-2.08h0v-.02c-.04-2.89-.53-5.6-1.44-8.15l-.04-.23h-.04c-1.22-3.32-3.16-6.35-5.85-9.04-4.9-4.9-10.89-7.38-17.81-7.38s-12.91,2.48-17.81,7.38c-4.9,4.9-7.38,10.89-7.38,17.81s2.48,12.91,7.38,17.81c4.9,4.9,10.89,7.38,17.81,7.38s12.91-2.48,17.81-7.38c3.69-3.69,6.01-8,6.93-12.87l-13.4,2.36Zm-20.86-16.85c2.69-2.66,5.8-3.96,9.53-3.96s6.89,1.29,9.55,3.95c.9.9,1.64,1.87,2.23,2.89l-25.1,4.43c.43-2.78,1.67-5.19,3.8-7.3Z"
      />
    </Svg>
  </Box>
);

export default Logo;
