import { useCallback, useEffect } from 'react';
import { useRouter } from 'src/routes/hooks';
import { PATH_AFTER_LOGIN } from 'src/config-global';
import { useUser } from '../hooks';

type Props = {
  children: React.ReactNode;
};

export default function AdminGuard({ children }: Props) {
  const router = useRouter();
  const { isAdmin } = useUser();

  const check = useCallback(() => {
    if (!isAdmin) {
      router.replace(PATH_AFTER_LOGIN);
    }
  }, [isAdmin, router]);

  useEffect(() => {
    check();
  }, [check]);

  return <>{children}</>;
}
