import { RoutePath } from 'src/types/path';

export const PAGE_TITLES: RoutePath = {
  auth: {
    login: 'ログイン',
  },
  consentForms: {
    root: '患者選択',
    detail: {
      patientConsents: '同意書一覧',
      templates: '同意書テンプレート選択',
      register: '同意書登録',
      displayMethod: '同意書表示方法の選択',
      signature: {
        root: '同意書一覧',
        complete: '同意書一覧',
      },
    },
  },
  patients: {
    root: '患者情報',
    register: '患者登録',
    detail: {
      edit: '患者詳細',
    },
  },
  templates: {
    root: '同意書テンプレート一覧',
    register: '同意書テンプレート登録',
    detail: {
      edit: '同意書テンプレート詳細',
    },
  },
  defaultDoctor: {
    root: '既定の医師選択',
  },
  users: {
    root: 'アカウント一覧',
    register: 'アカウント登録',
    detail: {
      edit: {
        root: 'アカウント詳細',
        delete: {
          complete: 'アカウントを削除しました。',
        },
        update: {
          complete: 'アカウント情報を更新しました。',
        },
      },
    },
  },
  organization: {
    root: '医療機関名編集',
  },
  myAccount: {
    root: 'アカウント情報',
    changePassword: {
      root: 'パスワード変更',
      complete: 'パスワード変更が完了しました。',
    },
  },
  error: {
    404: `お探しのページは見つかりませんでした。`,
  },
  contact: {
    root: 'お問合わせ',
  },
} as const;

export const MENU_TITLES = {
  consentForms: 'ホーム',
  patients: '患者管理',
  templates: '同意書テンプレート管理',
  defaultDoctor: '既定の医師選択',
  users: 'アカウント管理',
  organization: '医療機関名編集',
  contact: 'お問合わせ',
  terms: '利用規約',
  privacyPolicy: 'プライバシーポリシー',
};
