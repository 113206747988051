import { Box, BoxProps } from '@mui/material';
import { styled } from '@mui/material/styles';

const Svg = styled('svg')(() => ({
  '.st0': {
    fill: '#2797BA',
  },
  '.st1': {
    fill: '#D5311D',
  },
  'enable-background': 'new 0 0 90.71 90.71;',
}));

export const LogoMini = (sx: BoxProps['sx']) => (
  <Box sx={{ width: 45, height: 45, ...sx }}>
    <Svg
      version="1.1"
      id="レイヤー_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 90.71 90.71"
    >
      <g>
        <rect x="2.91" y="32.2" className="st0" width="11.65" height="44.81" />
        <path
          className="st1"
          d="M2.91,18.98L2.91,18.98c0-3.22,2.61-5.83,5.83-5.83h0c3.22,0,5.83,2.61,5.83,5.83v0
		c0,3.22-2.61,5.83-5.83,5.83h0C5.52,24.8,2.91,22.2,2.91,18.98z"
        />
        <path
          className="st0"
          d="M56.25,77.56c-8.77,0-16.36-3.15-22.57-9.36c-6.21-6.21-9.36-13.8-9.36-22.57c0-8.77,3.15-16.36,9.36-22.57
		c6.21-6.21,13.8-9.36,22.57-9.36c7.01,0,13.36,2.06,18.89,6.12c5.51,4.05,9.38,9.36,11.5,15.79l1.17,3.55H75.64l-0.71-1.62
		c-1.62-3.71-4.07-6.63-7.49-8.91c-3.41-2.27-7.07-3.38-11.2-3.38c-5.62,0-10.31,1.95-14.32,5.96s-5.96,8.73-5.96,14.42
		c0,5.62,1.95,10.31,5.96,14.32c4.01,4.01,8.69,5.96,14.32,5.96c4.13,0,7.79-1.1,11.2-3.38c3.42-2.28,5.87-5.19,7.49-8.91l0.71-1.62
		H87.8l-1.17,3.55c-2.12,6.43-5.99,11.76-11.49,15.84C69.62,75.48,63.26,77.56,56.25,77.56z"
        />
      </g>
      <g>
        <rect x="2.91" y="32.2" className="st0" width="11.65" height="44.81" />
        <path
          className="st1"
          d="M2.91,18.98L2.91,18.98c0-3.22,2.61-5.83,5.83-5.83h0c3.22,0,5.83,2.61,5.83,5.83v0
		c0,3.22-2.61,5.83-5.83,5.83h0C5.52,24.8,2.91,22.2,2.91,18.98z"
        />
        <path
          className="st0"
          d="M56.25,77.56c-8.77,0-16.36-3.15-22.57-9.36c-6.21-6.21-9.36-13.8-9.36-22.57c0-8.77,3.15-16.36,9.36-22.57
		c6.21-6.21,13.8-9.36,22.57-9.36c7.01,0,13.36,2.06,18.89,6.12c5.51,4.05,9.38,9.36,11.5,15.79l1.17,3.55H75.64l-0.71-1.62
		c-1.62-3.71-4.07-6.63-7.49-8.91c-3.41-2.27-7.07-3.38-11.2-3.38c-5.62,0-10.31,1.95-14.32,5.96s-5.96,8.73-5.96,14.42
		c0,5.62,1.95,10.31,5.96,14.32c4.01,4.01,8.69,5.96,14.32,5.96c4.13,0,7.79-1.1,11.2-3.38c3.42-2.28,5.87-5.19,7.49-8.91l0.71-1.62
		H87.8l-1.17,3.55c-2.12,6.43-5.99,11.76-11.49,15.84C69.62,75.48,63.26,77.56,56.25,77.56z"
        />
      </g>
    </Svg>
  </Box>
);
