import { Outlet, RouteObject } from 'react-router';
import { AuthGuard, AdminGuard } from 'src/auth/guard';
import DefaultLayout from 'src/layouts/default/layout';
import { Suspense, lazy } from 'react';
import { LoadingScreen } from 'src/components/loading-screen';
import CompactLayout from 'src/layouts/compact';
import { paths } from '../paths';

const UsersPage = lazy(() => import('src/pages/users'));
const UsersRegisterPage = lazy(() => import('src/pages/users/register'));
const UsersEditPage = lazy(() => import('src/pages/users/edit'));
const DeleteCompletePage = lazy(() => import('src/pages/users/deleteComplete'));
const UpdateCompletePage = lazy(() => import('src/pages/users/updateComplete'));

export const usersRoutes: RouteObject[] = [
  {
    element: (
      <AuthGuard>
        <AdminGuard>
          <DefaultLayout>
            <Suspense fallback={<LoadingScreen />}>
              <Outlet />
            </Suspense>
          </DefaultLayout>
        </AdminGuard>
      </AuthGuard>
    ),
    children: [
      {
        path: paths.users.root,
        element: <UsersPage />,
      },
      {
        path: paths.users.register,
        element: <UsersRegisterPage />,
      },
      {
        path: paths.users.detail.edit.root,
        element: <UsersEditPage />,
      },
    ],
  },
  {
    element: (
      <CompactLayout>
        <Outlet />
      </CompactLayout>
    ),
    children: [
      { path: paths.users.detail.edit.delete.complete, element: <DeleteCompletePage /> },
      { path: paths.users.detail.edit.update.complete, element: <UpdateCompletePage /> },
    ],
  },
];
