import { Suspense, lazy } from 'react';
import { Outlet, RouteObject } from 'react-router';
import { AuthGuard } from 'src/auth/guard';
import { LoadingScreen } from 'src/components/loading-screen';
import DefaultLayout from 'src/layouts/default/layout';
import { paths } from '../paths';

const PatientsPage = lazy(() => import('src/pages/patients'));
const PatientsRegisterPage = lazy(() => import('src/pages/patients/register'));
const PatientsDetailEditPage = lazy(() => import('src/pages/patients/detail/edit'));

export const patientsRoutes: RouteObject[] = [
  {
    element: (
      <AuthGuard>
        <DefaultLayout>
          <Suspense fallback={<LoadingScreen />}>
            <Outlet />
          </Suspense>
        </DefaultLayout>
      </AuthGuard>
    ),
    children: [
      {
        path: paths.patients.root,
        element: <PatientsPage />,
      },
      {
        path: paths.patients.register,
        element: <PatientsRegisterPage />,
      },
      {
        path: paths.patients.detail.edit,
        element: <PatientsDetailEditPage />,
      },
    ],
  },
];
