import { RoutePath } from 'src/types/path';

export const paths: RoutePath = {
  auth: {
    login: '/auth/login',
  },
  consentForms: {
    root: '/consent-forms',
    detail: {
      patientConsents: '/consent-forms/:patientId/patient-consents',
      templates: '/consent-forms/:patientId/templates',
      register: '/consent-forms/:patientId/register',
      displayMethod: '/consent-forms/:patientId/display-method',
      signature: {
        root: '/consent-forms/:patientId/signature',
        complete: '/consent-forms/:patientId/signature/complete',
      },
    },
  },
  patients: {
    root: '/patients',
    register: '/patients/register',
    detail: {
      edit: '/patients/:id/edit',
    },
  },
  templates: {
    root: '/templates',
    register: '/templates/register',
    detail: {
      edit: '/templates/:id/edit',
    },
  },
  defaultDoctor: {
    root: '/default-doctor',
  },
  users: {
    root: '/users',
    register: '/users/register',
    detail: {
      edit: {
        root: '/users/:id/edit',
        delete: {
          complete: '/users/:id/delete/complete',
        },
        update: {
          complete: '/users/:id/update/complete',
        },
      },
    },
  },
  organization: {
    root: '/organization',
  },
  myAccount: {
    root: '/my-account',
    changePassword: {
      root: '/my-account/change-password',
      complete: '/my-account/change-password/complete',
    },
  },
  error: {
    404: '/404',
  },
  contact: {
    root: '/contact',
  },
};
